import { v4 as uuidv4 } from 'uuid'
import { Options } from '../types/global'

enum MessageTypes {
    Init,
    SetHeight,
    ResetData,
    OnChange,
    OnValidChange,
    OnFormChange,
    SelectAsset,
    SelectContent,
    OnFormSubmit
}

class Message {
    public control: string
    public type: MessageTypes
    public data: any
    public requestId: string

    constructor(control: string, type: MessageTypes, data: any) {
        this.control = control
        this.type = type
        this.data = data
        this.requestId = uuidv4()
    }
}

export class ArragroCmsCustomControlClass {
    constructor (
        name: string,
        options: Options
    ) {
        if (window.self === window.top) {
            throw Error('The custom control must be hosted in an iframe')
        }
        this.name = name
        this.validateOptions(options)
        window.ArragroCms = {
            ArragroCmsCustomControlCallbacks: {
                [name]: options
            }
        }
        window.addEventListener('message', this.processMessage, true)
    }

    name: string

    private validateOptions (options: Options) {
        if (options.onInit === undefined) throw Error('You must supply onInit to options.') 
        if (options.onResetData === undefined) throw Error('You must supply onResetData to options.')
    }

    private processMessage (event: MessageEvent) {
        console.log('Message Received', event, event.data.message.type)
        const message = event.data.message as Message
        const payload = event.data.payload
        if (message !== undefined &&
            payload !== undefined) {
            switch (message.type) {
                case MessageTypes.Init: {
                    window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onInit(payload)
                    break;
                }
                case MessageTypes.ResetData: {
                    window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onResetData(payload)
                    break;
                }
                case MessageTypes.OnFormChange: {
                    if (window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onFormChange) {
                        window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onFormChange!(payload)
                    }
                    break;
                }
                case MessageTypes.OnFormSubmit: {
                    if (window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onFormSubmit) {
                        window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onFormSubmit!(payload)
                    }
                    break;
                }
                case MessageTypes.SelectAsset: {
                    if (window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onAssetSelected) {
                        window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onAssetSelected!(payload.url)
                    }
                    break
                }
                case MessageTypes.SelectContent: {
                    if (window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onContentSelected) {
                        window.ArragroCms.ArragroCmsCustomControlCallbacks[this.name].onContentSelected!(payload.url)
                    }
                    break
                }
                default:
                    throw Error('A message has been received we do not cater for')
            }
        }
    }

    private sendMessage (message: Message) {
        window.parent.postMessage(message, '*')
    }

    public Init () {
        const message = new Message(this.name, MessageTypes.Init, null)
        this.sendMessage(message)
    }

    public SetHeight (value: number) {
        const message = new Message(this.name, MessageTypes.SetHeight, { height: value })
        this.sendMessage(message)
    }

    public OnChange (name: string, value: any) {
        const message = new Message(this.name, MessageTypes.OnChange, { name, value })
        this.sendMessage(message)
    }

    public OnValidChange (isValid: boolean) {
        const message = new Message(this.name, MessageTypes.OnValidChange, { isValid })
        this.sendMessage(message)
    }

    public SelectAsset () {
        const message = new Message(this.name, MessageTypes.SelectAsset, {})
        this.sendMessage(message)
    }

    public SelectContent () {
        const message = new Message(this.name, MessageTypes.SelectContent, {})
        this.sendMessage(message)
    }
}
